import styled, { keyframes } from "styled-components";

export const CHART_COLORS = {
  PRIMARY: "#4CAF50", // Green
  COMPARISON: "#2196F3", // Blue
  GRID: "#ffffff", // White
  TEXT: "#ffffff", // White
  SKELETON_BACKGROUND: "gray",
  TOOLTIP: {
    BACKGROUND: "rgba(255, 255, 255, 0.95)",
    TEXT: "#666666", // Dark gray
    BORDER: "#eeeeee", // Light gray
    SHADOW: "rgba(0, 0, 0, 0.15)",
  },
};
export const FONT_SIZES = {
  AXIS_LABELS: 14,
  AXIS_TICKS: 12,
  TOOLTIP_TITLE: 14,
  TOOLTIP_SCORE: 14,
  TOOLTIP_INSIGHT: 12,
};

export const FONT_WEIGHTS = {
  NORMAL: 400,
  MEDIUM: 500,
  BOLD: 700,
};

export const SPACING = {
  XS: 4,
  SM: 8,
  MD: 12,
  LG: 16,
  XL: 20,
};

export const OPACITY = {
  RADAR_FILL: 0.3,
  TOOLTIP_BACKGROUND: 0.95,
};

export const CHART_DIMENSIONS = {
  WIDTH: 650,
  HEIGHT: 500,
  SVG_PADDING: 100, // Added SVG_PADDING
  OUTER_RADIUS: "60%",
  INNER_RADIUS: "10%",
  TOOLTIP_MIN_WIDTH: 200,
  TOOLTIP_MAX_WIDTH: 400,
  TOOLTIP_OFFSET: 20,
  LABEL_OFFSET: 40,
};

export const ChartContainer = styled.div`
  width: ${CHART_DIMENSIONS.WIDTH}px;
  height: ${CHART_DIMENSIONS.HEIGHT}px;
  position: relative;
`;

export const TooltipContainer = styled.div`
  background-color: ${CHART_COLORS.TOOLTIP.BACKGROUND};
  padding: ${SPACING.MD}px;
  border-radius: ${SPACING.XS}px;
  box-shadow: 0 2px 8px ${CHART_COLORS.TOOLTIP.SHADOW};
  margin: 10px;
  width: 100%;
  max-width: ${CHART_DIMENSIONS.TOOLTIP_MAX_WIDTH}px;
`;

export const TooltipTitle = styled.p`
  margin: 0 0 ${SPACING.SM}px;
  font-weight: ${FONT_WEIGHTS.BOLD};
  border-bottom: 1px solid ${CHART_COLORS.TOOLTIP.BORDER};
  padding-bottom: ${SPACING.XS}px;
  font-size: ${FONT_SIZES.TOOLTIP_TITLE}px;
  color: ${CHART_COLORS.TOOLTIP.TEXT};
`;

export const TooltipEntryContainer = styled.div<{ isLast: boolean }>`
  margin-bottom: ${(props) => (props.isLast ? 0 : SPACING.SM)}px;
`;

export const TooltipScore = styled.p<{ color: string }>`
  margin: 0;
  color: ${(props) => props.color};
  font-size: ${FONT_SIZES.TOOLTIP_SCORE}px;
  font-weight: ${FONT_WEIGHTS.MEDIUM};
`;

export const TooltipInsight = styled.p`
  margin: ${SPACING.XS}px 0 0;
  font-size: ${FONT_SIZES.TOOLTIP_INSIGHT}px;
  color: ${CHART_COLORS.TOOLTIP.TEXT};
  line-height: 1.4;
`;

const pulse = keyframes`
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.6;
  }
`;

export const SkeletonFilter = styled.div`
  height: 40px;
  background: ${CHART_COLORS.SKELETON_BACKGROUND};
  border-radius: 4px;
  animation: ${pulse} 1.5s ease-in-out infinite;
`;

export const SkeletonCircle = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  height: 400px;
  border: 2px solid ${CHART_COLORS.SKELETON_BACKGROUND};
  border-radius: 50%;
  animation: ${pulse} 1.5s ease-in-out infinite;
`;

export const SkeletonCross = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 650px;
  height: 500px;

  &:before,
  &:after {
    content: '';
    position: absolute;
    background: ${CHART_COLORS.SKELETON_BACKGROUND};
    animation: ${pulse} 1.5s ease-in-out infinite;
  }

  &:before {
    top: 50%;
    left: 0;
    right: 0;
    height: 2px;
    transform: translateY(-50%);
  }

  &:after {
    left: 50%;
    top: 0;
    bottom: 0;
    width: 2px;
    transform: translateX(-50%);
  }
`;
export const SkeletonChartContainer = styled.div`
  position: relative;
  aspect-ratio: 1;  // This ensures a square container
  width: 100%;
  max-width: 500px;  // Should match ChartContainer
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;

  .quadrant {
    position: absolute;
    width: 35%;
    height: 35%;
    background-color: rgba(128, 128, 128, 0.1);
    animation: pulse 2s infinite;
  }

  .top-right {
    top: 15%;     // Adjusted to center the smaller quadrants
    right: 15%;   // Adjusted to center the smaller quadrants
    animation-delay: 0s;
    border-top-right-radius: 100%;
  }

  .top-left {
    top: 15%;    // Adjusted to center the smaller quadrants
    left: 15%;   // Adjusted to center the smaller quadrants
    animation-delay: 0.5s;
    border-top-left-radius: 100%;
  }

  .bottom-left {
    bottom: 15%; // Adjusted to center the smaller quadrants
    left: 15%;   // Adjusted to center the smaller quadrants
    animation-delay: 1s;
    border-bottom-left-radius: 100%;
  }

  .bottom-right {
    bottom: 15%; // Adjusted to center the smaller quadrants
    right: 15%;  // Adjusted to center the smaller quadrants
    animation-delay: 1.5s;
    border-bottom-right-radius: 100%;
  }

  @keyframes pulse {
    0% {
      background-color: rgba(128, 128, 128, 0.1);
    }
    50% {
      background-color: rgba(128, 128, 128, 0.3);
    }
    100% {
      background-color: rgba(128, 128, 128, 0.1);
    }
  }
`;
