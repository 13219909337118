import { axiosInstance } from "axiosConfig";
import { PaginationData } from "types";
import { setSentryUser } from ".././sentry";
import { Dispatch } from 'redux';
import { setStages } from "state/appActions";

export const makeRequest = async (
  method: string,
  url: string,
  data?: object,
  headers?: object,
  responseType?: string
) => {
  switch (method) {
    case "GET":
      return await axiosInstance.get(url, { headers, withCredentials: true });
    case "GET-blob":
      return await axiosInstance.get(url, {
        responseType: "blob",
        withCredentials: true,
      });
    case "GET-CREDENTIALS":
      return await axiosInstance.get(url, {
        withCredentials: true,
      });
    case "POST":
      return await axiosInstance.post(url, data, { withCredentials: true });
    case "POST-FORM":
      return await axiosInstance.post(url, data, {
        headers: { "Content-Type": "multipart/form-data" },
        withCredentials: true,
      });
    case "PUT":
      return await axiosInstance.put(url, data, { withCredentials: true });
    case "DELETE":
      return await axiosInstance.delete(url, {
        data,
        withCredentials: true,
      });
  }
};

export const statusCheck = async () => {
  let response = await makeRequest("GET", `/api/status`);
};

/* --- USERS --- */

export const login = async (data: object) => {
  let response = await makeRequest("POST", `/login`, data);
  return response;
};

export const logout = async () => {
  let response = await makeRequest("POST", `/logout`);
  return response;
};

export const postForgotPassword = async (data: { email: string }) => {
  let response = await makeRequest("POST", `/forgot-password`, data);
  return response;
};

export const getIntroChecklist = async () => {
  let response = await makeRequest("GET", `/users/intro-checklist`);
  return response && response.data;
};

export const deleteForgotPassword = async (data: {
  password: string;
  magic_token: string;
}) => {
  let response = await makeRequest("DELETE", `/forgot-password`, data);
  return response;
};

export const adminLogin = async (data: object) => {
  let response = await makeRequest("POST", `/adminLogin`, data);
  return response;
};

export const verify = async () => {
  let response = await makeRequest("GET-CREDENTIALS", `/verify`);
  return response;
};

export const impersonateUser = async (data: object) => {
  let response = await makeRequest("POST", `/impersonate/user`, data);
  return response;
};

export const getUser = async (dispatch: Dispatch) => {
  let response = await makeRequest("GET", "/users/current-user");
  if (response?.data?.user?.email) {
    setSentryUser(response.data.user);
  }
  if (response?.data?.user?.stages) {
    dispatch(setStages(response.data.user.stages));
  }
  if (response?.data?.user?.email && response?.data?.user?.profile_picture_id) {
    let profile_pic_response = await makeRequest(
      "GET-blob",
      "/users/profile-picture"
    );
    if (profile_pic_response?.data) {
      const blob_url = URL.createObjectURL(profile_pic_response.data);
      response.data.user.image_url = blob_url;
    }
  }
  return response && response.data;
};

export const putUser = async (data: object) => {
  let response = await makeRequest("PUT", "/users/current-user", data);
  return response && response.data;
};

export const putSpecificUser = async (userId: string, data: object) => {
  let response = await makeRequest("PUT", `/users/${userId}`, data);
  return response && response.data;
};

export const signup = async (data: object) => {
  let response = await makeRequest("POST", "/register", data);
  return response && response.data;
};

export const postProfilePicture = async (data: FormData) => {
  let response = await makeRequest("POST-FORM", "/users/profile", data);
  return response && response.data;
};

/* --- PRE-CALL PREP --- */

export const postPrecallPrep = async (data: object) => {
  let response = await makeRequest("POST", "/precall-4", data);
  return response && response.data;
};

/* --- DEALS --- */

export const postGeneralFeedback = async (data: { deal_id: string }) => {
  let response = await makeRequest("POST", `/general-feedback`, data);
  return response && response.data;
};

export const postDealNotes = async (deal_id: string, data: { notes: string }) => {
  let response = await makeRequest("POST", `/deals/${deal_id}/notes`, data);
  return response && response.data;
};

// You'll also need to create a function to fetch existing notes
export const getDealNotes = async (dealId: string) => {
  const url = `/deals/${dealId}/notes`;
  let response = await makeRequest("GET", url);
  return response && response.data;
};

export const postMultipleEvaluations = async (data: object) => {
  debugger;
  let response = await makeRequest("POST", `/multiple-evaluation`, data);
  return response && response.data;
};

export const getIncomingCalls = async (weekOffset?: number) => {
  if (!weekOffset) weekOffset = 0;
  let response = await makeRequest(
    "GET",
    `/incoming-calls?week_offset=${weekOffset}`
  );
  if (response && response.data && response.data.redirect_url) {
    window.location.assign(response.data.redirect_url);
  }
  return response && response.data;
};

export const putIncomingCalls = async (data: {
  deal_id?: string;
  opportunity_id?: string;
  hubspot_deal_id?: string;
  record?: boolean;
  website?: string;
  incoming_call_id: string;
}) => {
  let response = await makeRequest("PUT", `/incoming-calls`, data);
  return response && response.data;
};

export const putDealRecommendation = async (
  deal_id: string,
  rec_id: string,
  completed: boolean
) => {
  let response = await makeRequest(
    "PUT",
    `/deals/${deal_id}/recommendation/${rec_id}`,
    { completed: completed }
  );
  return response && response.data;
};

export const dealEvaluationPost = async (data: any) => {
  (data as any).save = true;
  let response = await makeRequest("POST", `/evaluation_v4`, data);
  return response && response.data;
};

export const getDeal = async (dealID?: string) => {
  const url = `/deals/${dealID}`;
  let response = await makeRequest("GET", url);
  return response && response.data;
};

export const deleteDeal = async (dealID?: string) => {
  const url = `/deals/${dealID}`;
  let response = await makeRequest("DELETE", url);
  return response && response.data;
};

export const getDeals = async (data: PaginationData) => {
  let url = `/deals?sort_by=${data.sortBy}&sort_order=${data.sortOrder}&limit=${data.limit}&page=${data.page}&org_deals=${data.orgDeals}`;

  if (data.filterBy) {
    url += `&filter_by=${data.filterBy}`;
  } else if (data.searchBy) {
    url += `&search_by=${data.searchBy}`;
  }
  let response = await makeRequest("GET", url);
  return response && response.data;
};

export const getDealsQuick = async (org?: boolean) => {
  let response;
  if (org) {
    response = await makeRequest("GET", "/deals/quick?org=true");
  } else {
    response = await makeRequest("GET", "/deals/quick?org=false");
  }
  return response && response.data;
};

export const dealEvaluationFileUploadPost = async (data: object) => {
  let response = await makeRequest("POST-FORM", `/evaluation_v4`, data);
  return response && response.data;
};

export const postRadarInsights = async (data: {
  query: string;
  filter1: {
    deal_id: string | null;
    rep: string | null;
    stage: string | null;
    name: string | null;
    team_id: string | null;
  };
  filter2: {
    deal_id: string | null;
    rep: string | null;
    stage: string | null;
    name: string | null;
    team_id: string | null;
  };
}) => {
  const response = await makeRequest("POST", "/radar/the-answer", {
    query: data.query,
    filter1: {
      deal_id: data.filter1.deal_id,
      user_id: data.filter1.rep,
      stage: data.filter1.stage,
      name: data.filter1.name,
      team_id: data.filter1.team_id
    },
    filter2: {
      deal_id: data.filter2.deal_id, 
      user_id: data.filter2.rep,
      stage: data.filter2.stage,
      name: data.filter2.name,
      team_id: data.filter2.team_id
    }
  });
  return response && response.data;
};

export const postStageTimingInsights = async (data: {
    deal_id: string | undefined;
    user_id: string | undefined;
    team_id: string | undefined;
  }) => {
  const response = await makeRequest("POST", "/deals/stage-changes", {
    deal_id: data.deal_id,
    user_id: data.user_id,
    team_id: data.team_id
  });
  return response && response.data;
};

export const postOpportunityId = async (
  deal_id: string,
  data: { opportunity_id: string; opportunity_name: string }
) => {
  let response = await makeRequest(
    "POST",
    `/deals/${deal_id}/opportunity`,
    data
  );
  return response && response.data;
};

export const postHubspotDealId = async (
  deal_id: string,
  data: { hubspot_deal_id: string; hubspot_deal_name: string }
) => {
  let response = await makeRequest(
    "POST",
    `/deals/${deal_id}/hubspot-deal`,
    data
  );
  return response && response.data;
};

// Update deal information
export const putDeal = async (data: object, deal_id: string) => {
  let response = await makeRequest("PUT", `/deals/${deal_id}`, data);
  return response && response.data;
};

export const postDealShare = async (data: object, deal_id?: string) => {
  let response = await makeRequest("POST", `deals/${deal_id}/deal-share`, data);
  return response && response.data;
};

export const getCalls = async (dealId?: string) => {
  let response = await makeRequest("GET", `/deals/${dealId}/calls`);
  return response && response.data;
};

/* --- SALESFORCE --- */

export const getUserOpportunities = async () => {
  let response = await makeRequest("GET", `/salesforce-opportunity`);
  return response && response.data;
};

export const getOpportunityData = async (oppty_id?: string) => {
  let response = await makeRequest(
    "GET",
    `/salesforce-opportunity/${oppty_id}`
  );
  return response && response.data;
};

/* --- DEAL COACH --- */

export const getDealCoachPrePrompts = async (deal_id?: string) => {
  let response = await makeRequest("GET", `/dealcoach-preprompts/${deal_id}`);
  return response && response.data;
};

export const loadDealCoachMessages = async (
  dealId: string,
  pageNum: number,
  itemsPerPage: number
) => {
  let response = await makeRequest(
    "GET",
    `/deals/conversations?deal_id=${dealId}&page_num=${pageNum}&items_per_page=${itemsPerPage}`
  );
  return response && response.data;
};

export const loadAMADealCoachMessages = async (
  pageNum: number,
  itemsPerPage: number
) => {
  let response = await makeRequest(
    "GET",
    `/deals/conversations?deal_id=${1}&page_num=${pageNum}&items_per_page=${itemsPerPage}`
  );
  return response && response.data;
};

export const postDealCoach = async (data: object) => {
  let response = await makeRequest("POST", "/dealCoachStream", data);
  return response && response.data;
};

/* --- DEAL COMMENTS --- */
export const gettDealComments = async (dealId: string) => {
  let response = await makeRequest("GET", `/deals/${dealId}/comments`);
  return response && response.data;
};

export const postDealComment = async (dealId: string, data: object) => {
  let response = await makeRequest("POST", `/deals/${dealId}/comments`, data);
  return response && response.data;
};

export const deleteDealComment = async (
  dealId?: string,
  comment_id?: string
) => {
  let response = await makeRequest(
    "DELETE",
    `/deals/${dealId}/comments/${comment_id}`,
    {}
  );
  return response && response.data;
};

/* --- DASHBOARD --- */

// gets the average deal scores for all users in the org
export const getAverageDealScores = async () => {
  let response = await makeRequest("GET", `/users/average-score`);
  return response && response.data;
};

export const getOrganizationScores = async (filters?: {
  team_id?: string;
  user_id?: string;
  stage?: string;
  start_date?: string;
  end_date?: string;
}) => {
  let url = `/organizations/scores`;
  
  if (filters) {
    const queryParams = new URLSearchParams();
    if (filters.team_id) queryParams.append('team_id', filters.team_id);
    if (filters.user_id) queryParams.append('user_id', filters.user_id);
    if (filters.stage) queryParams.append('stage', filters.stage);
    if (filters.start_date) queryParams.append('start_date', filters.start_date);
    if (filters.end_date) queryParams.append('end_date', filters.end_date);
    
    if (queryParams.toString()) {
      url += `?${queryParams.toString()}`;
    }
  }

  let response = await makeRequest("GET", url);
  return response && response.data;
};

export const getOrganizationScoresRadar = async (filters?: {
  deal_id?: string;
  user_id?: string;
  stage?: string;
  start_date?: string;
  end_date?: string;
  team_id?: string;
}) => {
  let url = `/organizations/scores/radar`;
  
  if (filters) {
    const queryParams = new URLSearchParams();
    if (filters.deal_id) queryParams.append('deal_id', filters.deal_id);
    if (filters.user_id) queryParams.append('user_id', filters.user_id);
    if (filters.stage) queryParams.append('stage', filters.stage);
    if (filters.start_date) queryParams.append('start_date', filters.start_date);
    if (filters.end_date) queryParams.append('end_date', filters.end_date);
    if (filters.team_id) queryParams.append('team_id', filters.team_id);
    if (queryParams.toString()) {
      url += `?${queryParams.toString()}`;
    }
  }

  let response = await makeRequest("GET", url);
  return response && response.data;
};

/* --- ORGANIZATIONS --- */

export const getOrgResources = async () => {
  let response = await makeRequest("GET", `/org-resources`);
  return response && response.data;
};

export const getOrganizationInsights = async (filters?: {
  team_id?: string;
  user_id?: string;
  stage?: string;
  start_date?: string;
  end_date?: string;
}) => {
  let url = `/organizations/insights`;
  
  if (filters) {
    const queryParams = new URLSearchParams();
    if (filters.team_id) queryParams.append('team_id', filters.team_id);
    if (filters.user_id) queryParams.append('user_id', filters.user_id);
    if (filters.stage) queryParams.append('stage', filters.stage);
    if (filters.start_date) queryParams.append('start_date', filters.start_date);
    if (filters.end_date) queryParams.append('end_date', filters.end_date);
    
    if (queryParams.toString()) {
      url += `?${queryParams.toString()}`;
    }
  }

  let response = await makeRequest("GET", url);
  return response && response.data;
};

export const getOrgResource = async (resource_id: string) => {
  let response = await makeRequest("GET", `/org-resources/${resource_id}`);
  return response && response.data;
};

export const getTeams = async () => {
  let response = await makeRequest("GET", `/organizations/teams`);
  return response && response.data;
};

export const postTeam = async (data: object) => {
  let response = await makeRequest("POST", `/organizations/teams`, data);
  return response && response.data;
};

export const deleteTeam = async (data: object) => {
  let response = await makeRequest("DELETE", `/organizations/teams`, data);
  return response && response.data;
};
export const deleteOrgResource = async (resource_id: string) => {
  let response = await makeRequest(
    "DELETE",
    `/org-resources/${resource_id}`,
    {}
  );
  return response && response.data;
};

export const postOrgResource = async (data: object) => {
  let response = await makeRequest("POST-FORM", `/org-resources`, data);
  return response && response.data;
};

// Update organization information
export const putOrganization = async (data: object, org_id?: string) => {
  let response = await makeRequest("PUT", `/organizations/${org_id}`, data);
  return response && response.data;
};

// gets a list of company users + registration users
export const getOrganizationUsers = async () => {
  let response = await makeRequest("GET", `/organization-users`);
  return response && response.data;
};

// gets data about the user's organization and org users
export const getOrganization = async () => {
  let response = await makeRequest("GET", `/organizations/organization`);
  return response && response.data;
};

/* --- REGISTRATION USERS --- */

// creates the registration user
export const postRegistrationUser = async (data: object) => {
  let response = await makeRequest("POST", `/registration-user`, data);
  return response && response.data;
};

export const getRegistrationUser = async (magicToken: string) => {
  let response = await makeRequest("GET", `/registration-user/${magicToken}`);
  return response && response.data;
};

// remove: true passed in to delete the user, otherwise registers and deletes the user
export const deleteRegistrationUser = async (data: object) => {
  let response = await makeRequest("DELETE", `/registration-user`, data);
  return response && response.data;
};
// remove: true passed in to delete the user, otherwise registers and deletes the user
export const createUserFromRegUser = async (data: object, token: string) => {
  let response = await makeRequest(
    "DELETE",
    `/registration-user/${token}`,
    data
  );
  return response && response.data;
};

// Resends email and ups email count
export const putRegistrationUser = async (data: object) => {
  let response = await makeRequest("PUT", `/registration-user`, data);
  return response && response.data;
};

/* --- INTEGRATIONS --- */

export const getGoogleURL = async (org_id?: string) => {
  let response;
  if (org_id) {
    response = await makeRequest("GET", `/google?organization_id=${org_id}`);
  } else {
    response = await makeRequest("GET", "/google");
  }
  return response && response?.data;
};

export const getGoogleLoginURL = async (org_id?: string) => {
  let response;
  if (org_id) {
    response = await makeRequest("GET", `/google-login?organization_id=${org_id}`);
  } else {
    response = await makeRequest("GET", "/google-login");
  }
  return response && response?.data;
};

export const getZoomURL = async () => {
  let response = await makeRequest("GET", "/zoom");
  return response && response.data;
};

export const getSalesforceURL = async () => {
  let response = await makeRequest("GET", "/salesforce");
  return response && response.data;
};

export const getSlackURL = async () => {
  let response = await makeRequest("GET", "/slack");
  return response && response.data;
};

export const getTeamsURL = async () => {
  let response = await makeRequest("GET", "/microsoft");
  return response && response.data;
};

/* --- HUBSPOT --- */

export const getHubspotURL = async () => {
  let response = await makeRequest("GET", "/hubspot");
  return response && response.data;
};

export const getUserHubspotDeals = async () => {
  let response = await makeRequest("GET", "/hubspot-deals");
  return response && response.data;
};

export const getHubspotDealData = async (hubspot_deal_id?: string) => {
  let response = await makeRequest(
    "GET",
    `/hubspot-deals/${hubspot_deal_id}`
  );
  return response && response.data;
};

/* --- NOTIFICATIONS --- */

// Updates the notification to read
export const putNotification = async (notification_id: string) => {
  let response = await makeRequest(
    "PUT",
    `/notifications/${notification_id}`,
    {}
  );
  return response && response.data;
};

export const getNotifications = async () => {
  let response = await makeRequest("GET", `/notifications`);
  return response && response.data;
};

/* --- DEPRECATED --- */

export const postWaitlist = async (data: object) => {
  let response = await makeRequest("POST", "/waitlist", data);
  return response && response.data;
};

export const postDealCoachStatic = async (data: object) => {
  let response = await makeRequest("POST", `/dealCoach`, data);
  return response && response.data;
};
