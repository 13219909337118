import {
  FC,
  useEffect,
  useState,
  useRef,
  Dispatch,
  SetStateAction,
} from "react";
import moment from "moment";
import { sendAmplitudeData } from "amplitude";
import {
  DealPageWrapper,
  Deal as DealDiv,
  CategoryWrapper,
  DealHeader,
  Category as CategoryDiv,
  Feedback,
  TabsWrapper,
  ScoreBox,
  FloatingBox,
  Tab,
  WidgetsWrapper,
  ScoreBar,
  CircleScore,
  Title,
  CheckboxInput,
  CheckboxLabel,
  StrikeThroughText,
  ScoreLoader,
  SummaryLoader,
} from "./DealPage.styled";
import {
  FloatingWrapper,
  GradientText,
} from "shared/shared_styled_comps/components.styled";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
  getDeal,
  putDeal,
  putDealRecommendation,
  getCalls,
  postGeneralFeedback,
  getUserOpportunities,
  getUserHubspotDeals,
  postOpportunityId,
  getOpportunityData,
  postHubspotDealId,
  getHubspotDealData,
} from "api/helpers";
import { useDispatch, useSelector } from "react-redux";
import { startLoading, endLoading, setError } from "state/appActions";
import AddDeal from "components/AddDeal/AddDeal";
import DealSummary from "components/DealSummary/DealSummary";
import Transcript from "components/Transcript/Transcript";
import PageWrapper from "components/reusable/PageWrapper/PageWrapper";
import { ScoreBox as TotalScoreBox } from "../DealSummary/DealSummary.styled";
import { DropDown } from "shared/shared_styled_comps/forms.styled";
import ScoreBarChart from "components/widgets/ScoreBarChart/ScoreBarChart";
import DealCoachChat from "components/DealCoachChat/DealCoachChat";
import CheckWithCircle from "static/svgs/CheckWithCircle";
import Currency from "static/svgs/Currency";
import Cycle from "static/svgs/Cycle";
import CompanyBreakdown from "components/CompanyBreakdown/CompanyBreakdown";
import Upload from "static/svgs/Upload";
import Share from "static/svgs/Share";
import ShareModal from "components/Share/Share";
import {
  MyObject,
  Contact,
  Call,
  Deal,
  Recommendation,
  Category,
  GeneralFeedback,
  User,
  Opportunity,
  HubspotDeal,
  Stage,
} from "types";
import {
  AddDealWrapper,
  AddDealModal,
} from "components/SideBar/SideBar.styled";
import ToolTip from "components/ToolTip/ToolTip";
import { Position } from "@reactour/tour";
import DealComments from "components/DealComments/DealComments";
import Edit from "static/svgs/Edit";
import SearchableDropdown from "components/SearchableDropdown/SearchableDropdown";
import DealNotes from "components/DealNotes/DealNotes";
import SpiderChart from "components/SpiderChart/SpiderChart";

interface DealPageProps {
  tourStep?: number;
  setTourStep?: Dispatch<SetStateAction<number>>;
  tourPosition?: Position;
  setTourPosition?: Dispatch<SetStateAction<Position>>;
  setTourIsOpen?: Dispatch<SetStateAction<boolean>>;
}

const tabs = ["Summary", "Recommendations", "Pre-call Prep", "Deal Coach"];

const DealPage: FC<DealPageProps> = ({
  tourStep,
  setTourStep,
  setTourIsOpen,
  tourPosition,
  setTourPosition,
}) => {
  let { dealID } = useParams();
  let dispatch = useDispatch();
  const [data, setData] = useState<Deal>();
  const [calls, setCalls] = useState<Call[]>();
  const [currentCallId, setCurrentCallId] = useState<string>();
  const [activeVideoURL, setActiveVideoURL] = useState<string>();
  const modalRef = useRef<HTMLDivElement>(null);
  const crmTipRef = useRef<HTMLDivElement>(null);
  const crmContainerRef = useRef<HTMLDivElement>(null);
  const childRef = useRef<{ send: (message: string) => void }>(null);
  const [addCall, setAddCall] = useState(false);
  const [share, setShare] = useState(false);
  const [dealMappingError, setDealMappingError] = useState({
    error: false,
    deal_name: "",
  });

  // Salesforce
  const [confirmOppty, setConfirmOppty] = useState(false);
  const [opportunityData, setOpportunityData] = useState<Opportunity>();
  const [opportunities, setOpportunities] = useState<Opportunity[]>([]);
  const [currentOpportunityId, setCurrentOpportunityId] = useState<string>();

  // Hubpost
  const [confirmHubspotDeal, setConfirmHubspotDeal] = useState(false);
  const [hubspotDeals, setHubpostDeals] = useState<HubspotDeal[]>([]);
  const [currentHubspotId, setCurrentHubspotId] = useState<string>();
  const [hubspotDealData, setHubspotDealData] = useState<HubspotDeal>();

  const [mainContact, setMainContact] = useState<Contact>();
  const [activeTab, setTab] = useState(0);
  const [generalFeedBack, setGeneralFeedBack] = useState<GeneralFeedback[]>([]);
  const [activeTabs, setActiveTabs] = useState(tabs);
  const [currentStage, setCurrentStage] = useState<Stage>();
  const [dealId, setDealId] = useState("");
  const [editDeal, setEditDeal] = useState(false);
  const [dealName, setDealName] = useState("");
  const [messagesend, setMessagsesend] = useState("");
  const [connectCRM, setConnectCRM] = useState(false);
  const [nextSteps, setNextSteps] = useState<MyObject>({});
  const isLoading = useSelector((state: any) => state.app.isLoading);
  const timeoutRef = useRef<number | null>(null);
  const navigate = useNavigate();
  const query = new URLSearchParams(useLocation().search);
  const newQuery = new URLSearchParams(query.toString());
  const retry = query.get("retry");

  // Notes
  const [isNotesOpen, setIsNotesOpen] = useState(false);

  const stages = useSelector((state: any) => state.app.stages);
  const user: User = useSelector((state: any) => state.auth.user);
  const userOwnsDeal = data?.user_id === user?._id;

  const hideCRMTip = () => {
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = window.setTimeout(() => {
      setConnectCRM(false);
      timeoutRef.current = null;
    }, 300);
  };

  const handleClickOutside = (event: React.MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      setAddCall(false);
      setShare(false);
      setConfirmHubspotDeal(false);
      setConfirmOppty(false);
    }
  };

  const handleCallChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setCurrentCallId(event.target.value);
    // Update the data to show to the current call's data
    let newData = data;
    let currentCall: Call | undefined = calls?.find(
      (call) => call.call_id === event.target.value
    );
    setNextSteps({});
    if (currentCall && newData) {
      newData.most_recent_call.transcript = currentCall.transcript;
      let stats = currentCall.stats;
      let nextStepsObject: MyObject = {};
      newData.overall_deal_score = currentCall.overall_deal_score;
      newData.highest_scores.forEach((category: Category, index: number) => {
        let categoryStats = stats[category.category_name];
        if (categoryStats) {
          category.feedback = categoryStats.feedback;
          if (categoryStats.scores.length > 0) {
            category.highest_score = categoryStats.scores[0].category_score;
          }
        }
        if (categoryStats.recommendations) {
          category.recommendations = categoryStats.recommendations;
          categoryStats.recommendations.map(
            (recommendation: Recommendation) => {
              nextStepsObject[recommendation.rec_id] = recommendation.completed;
            }
          );
        }
      });
      setNextSteps(nextStepsObject);
    }
    setData(newData);
    console.log("New data", newData);
  };

  const handleMessageSend = async (message: string) => {
    // Swap to the deal coach tab
    setTab(3);
    // Set data to trigger a message send
    if (message !== "" && childRef && childRef.current) {
      childRef.current.send(message);
    }
  };

  const handleCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    key: string
  ) => {
    const checked = e.target.checked;
    // Update rec on backend
    putDealRecommendation(dealID || "", key, checked);
    // Update rec on frontend
    setNextSteps((prevObject) => ({
      ...prevObject,
      [key]: checked,
    }));
  };

  const changeHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedStageIndex = event.target.value;
    // Handle the selected value
    setCurrentStage(stages[selectedStageIndex]);
    putDeal({ deal_stage: stages[selectedStageIndex]["label"] }, dealId);
  };

  useEffect(() => {
    if (data?.deal_stage && stages) {
      let stage;
      if (data?.deal_stage?.label) {
        stage = stages.find((stage: Stage) =>
          stage.label
            .toLowerCase()
            .includes(data.deal_stage.label.toLowerCase())
        );
      } else {
        stage = stages.find((stage: Stage) =>
          stage.label.toLowerCase().includes(data.deal_stage.toLowerCase())
        );
      }
      setCurrentStage(stage);
    }
  }, [stages, data?.deal_stage]);

  const handleDealNameChange = () => {
    const newDealName = dealName;
    if (data) {
      data.deal_name = newDealName;
    }
    setEditDeal(false);
    putDeal({ deal_name: newDealName }, dealId);
  };

  const handleOpportunityChange = async () => {
    setConfirmOppty(false);
    const opportunity = opportunities.find(
      (opportunity) => opportunity.Id === currentOpportunityId
    );
    if (opportunity) {
      let response = await postOpportunityId(dealId, {
        opportunity_id: opportunity.Id,
        opportunity_name: opportunity?.Name,
      });
      if (response.existing_deal_id) {
        setDealMappingError({
          error: true,
          deal_name: response.existing_deal_name,
        });
      } else {
        window.location.reload();
      }
    }
  };

  const handleHubspotDealChange = async () => {
    setConfirmHubspotDeal(false);
    const hubspotDeal = hubspotDeals.find(
      (deal) => deal.id === currentHubspotId
    );
    if (hubspotDeal) {
      let response = await postHubspotDealId(dealId, {
        hubspot_deal_id: hubspotDeal.id,
        hubspot_deal_name: hubspotDeal?.properties?.dealname,
      });
      if (response.existing_deal_id) {
        setDealMappingError({
          error: true,
          deal_name: response.existing_deal_name,
        });
      } else {
        window.location.reload();
      }
    }
  };

  const handleMouseEnter = () => {
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
    setConnectCRM(true);
  };

  const handleMouseLeave = () => {
    hideCRMTip();
  };
  useEffect(() => {
    if (crmContainerRef.current) {
      const container = crmContainerRef.current;
      container.addEventListener("mouseenter", handleMouseEnter);
      container.addEventListener("mouseleave", handleMouseLeave);

      return () => {
        container.removeEventListener("mouseenter", handleMouseEnter);
        container.removeEventListener("mouseleave", handleMouseLeave);
      };
    }
  }, [crmContainerRef.current]);

  useEffect(() => {
    if (crmTipRef.current) {
      const container = crmTipRef.current;
      container.addEventListener("mouseenter", handleMouseEnter);
      container.addEventListener("mouseleave", handleMouseLeave);

      return () => {
        container.removeEventListener("mouseenter", handleMouseEnter);
        container.removeEventListener("mouseleave", handleMouseLeave);
      };
    }
  }, [crmTipRef.current]);

  function addLineBreaks(text: string): string {
    if (!text) return "";
    if (Array.isArray(text)) {
      return text;
    }
    const pattern = /(\d+\.)|(-\s)/g;
    // Substitute the pattern with a line break followed by the matched group
    let modifiedText = text.replace(pattern, "\n$1$2");
    // Remove any leading line breaks that may have been added
    modifiedText = modifiedText.replace(/^\n/, "");
    return modifiedText;
  }

  useEffect(() => {
    const fetchDealData = async () => {
      dispatch(startLoading());
      const [data, generalFeedBackResponse] = await Promise.all([
        getDeal(dealID),
        postGeneralFeedback({ deal_id: dealID || "" }),
      ]);
      // Deal data could not be found
      if (!data) {
        dispatch(
          setError(
            "Deal not found. You could be trying to access a deal that is not available to you."
          )
        );
      }
      setDealName(data?.deal_name || "");
      if (data?.most_recent_call?.transcript && !tabs.includes("Recordings")) {
        tabs.push("Recordings");
      }
      if (generalFeedBackResponse?.gen_feedback?.gen_feedback) {
        const genFeedback =
          generalFeedBackResponse?.gen_feedback?.gen_feedback.map(
            (feedback: GeneralFeedback) => ({
              ...feedback,
              value: addLineBreaks(feedback.value),
            })
          );
        setGeneralFeedBack(genFeedback);
      } else {
        const genFeedback = generalFeedBackResponse?.gen_feedback?.map(
          (feedback: GeneralFeedback) => ({
            ...feedback,
            value: addLineBreaks(feedback.value),
          })
        );
        setGeneralFeedBack(genFeedback);
      }
      console.log("Deal data:", data);
      console.log("General Feedback:", generalFeedBackResponse);
      if (
        user &&
        !user?.completed_intro_tour &&
        tourStep &&
        setTourIsOpen &&
        tourStep > 0
      ) {
        setTourIsOpen(true);
      }
      dispatch(endLoading());
      setData(data ? data : { message: "No data" });
      if (data?._id) {
        setDealId(data._id);
      }
      if (data?.video_url) {
        setActiveVideoURL(data.video_url);
      }
      if (data?.highest_scores?.length > 0) {
        let nextStepsObject: MyObject = {};
        data.highest_scores.forEach((category: Category, index: number) => {
          if (category?.recommendations) {
            category.recommendations.map((recommendation: Recommendation) => {
              nextStepsObject[recommendation.rec_id] = recommendation.completed;
            });
          }
        });
        setNextSteps(nextStepsObject);
      } else {
        // No score data, which means only precall prep was generated.
        setActiveTabs(["Pre-call Prep"]);
        setTab(2);
      }
      if (data?.contacts) {
        setMainContact(
          data.contacts.find(
            (contact: Contact) => contact.speaker_type === "buyer"
          )
        );
      }
      let callResponse = await getCalls(dealID);
      console.log("Call data:", callResponse);
      if (callResponse) {
        setCalls(callResponse?.calls);
      }
    };
    fetchDealData();
  }, [dealID]);

  useEffect(() => {
    const fetchOpportunities = async () => {
      let response = await getUserOpportunities();
      setOpportunities(response.reverse());
    };
    const fetchHubspotDeals = async () => {
      let response = await getUserHubspotDeals();
      setHubpostDeals(response);
    };
    const fetchHubspotDeal = async () => {
      let response = await getHubspotDealData(data?.hubspot_deal_id);
      console.log("Hubspot Deal:", response);
      setHubspotDealData(response);
    };
    const fetchOpportunity = async () => {
      let response = await getOpportunityData(data?.opportunity_id);
      console.log("Salesforce Opportunity:", response);
      setOpportunityData(response);
    };
    if (
      user.organization_info?.salesforce_token &&
      data &&
      !data?.opportunity_id
    ) {
      fetchOpportunities();
    } else if (data?.opportunity_id) {
      fetchOpportunity();
    } else if (
      user.organization_info?.hubspot_token &&
      data &&
      !data?.hubspot_deal_id
    ) {
      fetchHubspotDeals();
    } else if (data?.hubspot_deal_id) {
      fetchHubspotDeal();
    }
  }, [data, user]);

  const renderTabContent = () => {
    return (
      <>
        <div style={{ display: activeTab === 0 ? "contents" : "none" }}>
          {activeTabs.includes("Summary") ? (
            <DealSummary
              categories={data?.highest_scores}
              contacts={data?.contacts}
              stage={currentStage}
              stages={stages}
              generalFeedback={generalFeedBack}
              handleMessageSend={handleMessageSend}
              opportunityData={opportunityData}
              hubspotDealData={hubspotDealData}
              user={user}
              crmEnabled={Boolean(user.organization_info?.salesforce_token || user.organization_info?.hubspot_token)}
            />
          ) : (
            <div onClick={() => setAddCall(true)} className="upload">
              <div className="svg-wrapper">
                <Upload />
              </div>
              <GradientText>Upload a Call to Get Started</GradientText>
            </div>
          )}
        </div>
        <div style={{ display: activeTab === 1 ? "contents" : "none" }}>
          {activeTabs.includes("Recommendations") ? (
            <>
              <p className="white-underline">Next Steps</p>
              <DealDiv>
                <CategoryWrapper>
                  {data &&
                    data.highest_scores.map(
                      (category: Category, categoryIndex: number) => (
                        <CategoryDiv key={categoryIndex}>
                          <div className="title-wrapper">
                            <ScoreBox score={category.highest_score}>
                              {category.category_name[0]}
                            </ScoreBox>
                            <h1>{category.category_name}</h1>
                          </div>
                          {category.recommendations &&
                            category.recommendations.map(
                              (
                                recommendation: Recommendation,
                                index: number
                              ) => (
                                <div key={index} className="flex-wrapper">
                                  <CheckboxInput
                                    type="checkbox"
                                    checked={nextSteps[recommendation.rec_id]}
                                    onChange={(e) =>
                                      handleCheckboxChange(
                                        e,
                                        recommendation.rec_id
                                      )
                                    }
                                    id={recommendation.rec_id}
                                  />
                                  <CheckboxLabel
                                    className="box"
                                    htmlFor={recommendation.rec_id}
                                  >
                                    <CheckWithCircle />
                                  </CheckboxLabel>
                                  {nextSteps[recommendation.rec_id] ? (
                                    <StrikeThroughText>
                                      {recommendation.text}
                                    </StrikeThroughText>
                                  ) : (
                                    <p>{recommendation.text}</p>
                                  )}
                                </div>
                              )
                            )}
                        </CategoryDiv>
                      )
                    )}
                </CategoryWrapper>
              </DealDiv>
            </>
          ) : (
            <div onClick={() => setAddCall(true)} className="upload">
              <div className="svg-wrapper">
                <Upload />
              </div>
              <GradientText>Upload a Call to Get Started</GradientText>
            </div>
          )}
        </div>
        <div style={{ display: activeTab === 2 ? "contents" : "none" }}>
          <CompanyBreakdown
            company={
              data?.companies && data?.companies?.length > 0
                ? data.companies[0]
                : null
            }
            handleMessageSend={handleMessageSend}
            dealID={dealID || ""}
          />
        </div>
        <div style={{ display: activeTab === 3 ? "contents" : "none" }}>
          <DealCoachChat ref={childRef} deal_id={dealId} height={"83%"} />
        </div>
        <div style={{ display: activeTab === 4 ? "contents" : "none" }}>
          <Transcript
            video_url={activeVideoURL}
            transcript={data?.most_recent_call?.transcript?.transcript?.replace(
              /\n/g,
              "<br />"
            )}
          />
        </div>
      </>
    );
  };

  const handleSelectTab = (index: number) => {
    sendAmplitudeData("Tab Clicked", {
      tab_name: tabs[index],
      deal_id: dealID,
    });
    if (!user?.completed_intro_tour && tourStep && setTourStep) {
      setTab(index);
      setTourStep(tourStep + 1);
    } else {
      setTab(index);
    }
  };

  useEffect(() => {
    const handleBack = () => {
      if (tourStep === 9) {
        setTab(0);
      }
      if (tourStep === 11) {
        setTab(1);
      }
      if (tourStep === 13) {
        setTab(2);
      }
    };
    if (!user?.completed_intro_tour) {
      handleBack();
    }
  }, [tourStep]);

  const handleDisabledTabs = (index: number) => {
    let disabled = true;
    if (user?.completed_intro_tour) {
      disabled = false;
    }
    if (tourStep === 9 && index === 1) {
      disabled = false;
    }
    if (tourStep === 11 && index === 2) {
      disabled = false;
    }
    if (tourStep === 13 && index === 3) {
      disabled = false;
    }
    return disabled;
  };

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredOpportunities, setFilteredOpportunities] = useState<
    Opportunity[]
  >([]);
  const [selectedOpportunity, setSelectedOpportunity] =
    useState<Opportunity | null>(null);

  const handleOpportunitySelect = (opportunity: Opportunity | null) => {
    if (opportunity) {
      setCurrentOpportunityId(opportunity.Id);
    } else {
      setCurrentOpportunityId("");
    }
  };

  useEffect(() => {
    if (opportunities) {
      setFilteredOpportunities(
        opportunities.filter((oppty) =>
          oppty.Name.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }
  }, [searchTerm, opportunities]);

  const [currentHubSpotDealId, setCurrentHubSpotDealId] = useState<string>("");

  const handleHubSpotDealSelect = (deal: HubspotDeal | null) => {
    if (deal) {
      setCurrentHubSpotDealId(deal.id);
      setCurrentHubspotId(deal.id);
    } else {
      setCurrentHubSpotDealId("");
    }
  };

  return (
    <PageWrapper pageTitle={""}>
      <Title>
        <div
          onClick={() => setIsNotesOpen(true)}
          className="notes-button-wrapper"
        >
          <Edit />
        </div>
        {!editDeal ? (
          <div className="deal-name-wrapper" style={{ marginRight: "30px" }}>
            <h2>{data && data.deal_name}</h2>
            <div onClick={() => setEditDeal(true)}>
              <Edit />
            </div>
          </div>
        ) : (
          <div className="deal-name-wrapper" style={{ marginRight: "30px" }}>
            <input
              value={dealName}
              onChange={(e) => setDealName(e.target.value)}
            />
            <div className="checkmark" onClick={() => handleDealNameChange()}>
              &#x2713;
            </div>
          </div>
        )}
        <div className="flex">
          <div className="" style={{ marginRight: "30px" }}>
            <h5
              style={{
                marginTop: "21px",
                marginRight: "10px",
                marginBottom: "5px",
              }}
            >
              Current Call:{" "}
            </h5>
            <DropDown
              value={currentCallId}
              onChange={(e) => handleCallChange(e)}
            >
              {calls?.map((call: Call, index: number) => (
                <option value={call.call_id}>
                  {call.call_date
                    ? moment(call.call_date).format("MMM DD, YYYY")
                    : moment(call.timestamp).format("MMM DD, YYYY")}
                </option>
              ))}
            </DropDown>
            {currentCallId &&
              currentCallId !== data?.most_recent_call.call_id && (
                <p className="error">You are currently viewing a past call</p>
              )}
          </div>
          {!opportunityData && !hubspotDealData && (
            <div className="stage-wrapper" style={{ marginRight: "50px" }}>
              <h5 style={{ marginRight: "10px", marginBottom: "5px" }}>
                Deal Stage:
              </h5>
              <DropDown
                value={currentStage?.sortOrder}
                onChange={(e) => changeHandler(e)}
              >
                {stages.map((stage: Stage, index: number) => (
                  <option key={index} value={stage.sortOrder}>
                    {stage.label}
                  </option>
                ))}
              </DropDown>
            </div>
          )}
          {/* Only show oppty dropdown if user has salesforce token */}
          {user.organization_info?.salesforce_token &&
            userOwnsDeal &&
            opportunities &&
            opportunities.length > 0 && (
              <div className="opportunity-section">
                <h5 style={{ marginBottom: "5px" }}>
                  Select a Salesforce Opportunity:
                </h5>
                <div className="opportunity-wrapper">
                  <SearchableDropdown<Opportunity>
                    items={opportunities}
                    displayProperty="Name"
                    idProperty="Id"
                    onSelect={handleOpportunitySelect}
                    placeholder="Search opportunities..."
                    selectedItem={
                      opportunities.find(
                        (o) => o.Id === currentOpportunityId
                      ) || null
                    }
                  />
                  <div
                    className="checkmark"
                    onClick={
                      currentOpportunityId
                        ? () => setConfirmOppty(true)
                        : undefined
                    }
                  >
                    &#x2713;
                  </div>
                </div>
              </div>
            )}
          {user.organization_info?.hubspot_token &&
            userOwnsDeal &&
            hubspotDeals &&
            hubspotDeals.length > 0 && (
              <div className="hubspot-deal-section">
                <h5 style={{ marginBottom: "5px" }}>Select a HubSpot Deal:</h5>
                <div className="hubspot-deal-wrapper">
                  <SearchableDropdown<HubspotDeal>
                    items={hubspotDeals}
                    displayProperty={(deal) => deal.properties.dealname}
                    idProperty="id"
                    onSelect={handleHubSpotDealSelect}
                    placeholder="Search HubSpot deals..."
                    selectedItem={
                      hubspotDeals.find((d) => d.id === currentHubSpotDealId) ||
                      null
                    }
                  />
                  <div
                    className="checkmark"
                    onClick={
                      currentHubSpotDealId
                        ? () => setConfirmHubspotDeal(true)
                        : undefined
                    }
                  >
                    &#x2713;
                  </div>
                </div>
              </div>
            )}
          {data?.opportunity_id && (
            <>
              <div className="" style={{ marginRight: "20px" }}>
                <h5 style={{ marginRight: "10px", marginBottom: "5px" }}>
                  Deal Value:
                </h5>
                <p style={{ marginRight: "0px", marginTop: "0px" }}>
                  ${opportunityData?.Amount}
                </p>
              </div>
              <div className="" style={{ marginRight: "20px" }}>
                <h5 style={{ marginRight: "10px", marginBottom: "5px" }}>
                  Close Date:
                </h5>
                <p style={{ marginRight: "0px", marginTop: "0px" }}>
                  {opportunityData?.CloseDate}
                </p>
              </div>

              <div className="stage-wrapper" style={{ marginRight: "20px" }}>
                <h5 style={{ marginRight: "10px", marginBottom: "5px" }}>
                  Deal Stage:
                </h5>
                {opportunityData && (
                  <p style={{ marginTop: "0px" }}>
                    {opportunityData.StageName}
                  </p>
                )}
              </div>
              <div className="">
                <a
                  className="opportunity-name"
                  style={{ marginRight: "30px", marginTop: "0px" }}
                  href={opportunityData?.opportunity_link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Salesforce Opportunity Link
                </a>
              </div>
            </>
          )}
          {data?.hubspot_deal_id && (
            <>
              <div className="">
                <h5 style={{ marginRight: "10px", marginBottom: "5px" }}>
                  Deal Value:
                </h5>
                <p style={{ marginRight: "50px", marginTop: "0px" }}>
                  ${hubspotDealData?.properties.amount}
                </p>
              </div>
              <div className="">
                <h5 style={{ marginRight: "10px", marginBottom: "5px" }}>
                  Close Date:
                </h5>
                <p style={{ marginRight: "50px", marginTop: "0px" }}>
                  {moment(hubspotDealData?.properties.closedate).format(
                    "MM-DD-YYYY"
                  )}
                </p>
              </div>
              <div className="stage-wrapper" style={{ marginRight: "50px" }}>
                <h5 style={{ marginRight: "10px", marginBottom: "5px" }}>
                  Deal Stage:
                </h5>
                {hubspotDealData && (
                  <p style={{ marginTop: "0px" }}>
                    {hubspotDealData?.properties.dealstage}
                  </p>
                )}
              </div>
              <div className="">
                <a
                  className="opportunity-name"
                  style={{ marginRight: "30px", marginTop: "0px" }}
                  href={hubspotDealData?.deal_link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Hubspot Deal Link
                </a>
              </div>
            </>
          )}
        </div>
      </Title>
      {data ? (
        <ScoreBar>
          {activeTabs.includes("Recommendations") && (
            <>
              <div className="score-circle-wrapper">
                <CircleScore
                  index={0}
                  score={data && data.overall_deal_score / 10}
                >
                  {data && Math.round(data.overall_deal_score)}
                  <span> /100</span>
                </CircleScore>
                <p>Overall</p>
              </div>
              {data?.highest_scores.map((category: Category, index: number) => (
                <div className="score-circle-wrapper">
                  <CircleScore index={index + 1} score={category.highest_score}>
                    {Math.round(category.highest_score * 10)}
                  </CircleScore>
                  <p>{category.category_name}</p>
                </div>
              ))}
            </>
          )}
        </ScoreBar>
      ) : (
        <ScoreLoader></ScoreLoader>
      )}
      <DealPageWrapper className="deal-page">
        <div className="left-wrapper">
          <FloatingWrapper
            className="floating-wrapper-a"
            height={"715px"}
            width={"880px"}
          >
            <DealHeader>
              <TabsWrapper className="deal-tabs-wrapper">
                {tabs.map((tab: string, index: number) => {
                  const tabClass = tab.toLowerCase().replace(/ /g, "-");
                  return (
                    <>
                      <Tab
                        active={activeTab === index}
                        key={index}
                        onClick={() => handleSelectTab(index)}
                        className={tabClass}
                        disabled={handleDisabledTabs(index)}
                      >
                        {tab}
                      </Tab>
                      {index !== tabs.length - 1 && <hr />}
                    </>
                  );
                })}
              </TabsWrapper>
            </DealHeader>
            {data ? <>{renderTabContent()}</> : <SummaryLoader></SummaryLoader>}
          </FloatingWrapper>
        </div>
        <div>
          <DealComments dealId={dealID} />
        </div>
        {/* <div>
          <SpiderChart data={data?.highest_scores} comparisonData={undefined} />
        </div> */}
        <DealNotes
          isOpen={isNotesOpen}
          onClose={() => setIsNotesOpen(false)}
          dealId={dealId}
        />
        {addCall && (
          <AddDealWrapper onClick={(e) => handleClickOutside(e)}>
            <AddDealModal ref={modalRef}>
              <AddDeal setModal={setAddCall} currentDealId={dealID} />
            </AddDealModal>
          </AddDealWrapper>
        )}
        {share && (
          <AddDealWrapper onClick={(e) => handleClickOutside(e)}>
            <AddDealModal ref={modalRef}>
              <ShareModal setModal={setAddCall} currentDealId={dealID} />
            </AddDealModal>
          </AddDealWrapper>
        )}
        {confirmOppty && (
          <AddDealWrapper onClick={(e) => handleClickOutside(e)}>
            <AddDealModal ref={modalRef}>
              <div className="confirm-oppty">
                <h2>Are you sure you want to link this opportunity?</h2>
                <p>
                  This will update the opportunity with deal data in accordance
                  with your company's preferences.
                </p>
                <button onClick={() => handleOpportunityChange()}>
                  Confirm
                </button>
                <button onClick={() => setConfirmOppty(false)}>Cancel</button>
              </div>
            </AddDealModal>
          </AddDealWrapper>
        )}
        {confirmHubspotDeal && (
          <AddDealWrapper onClick={(e) => handleClickOutside(e)}>
            <AddDealModal ref={modalRef}>
              <div className="confirm-oppty">
                <h2>Are you sure you want to link this deal?</h2>
                <p>
                  This will update the Hubapot Deal with Leadoff deal data in
                  accordance with your company's preferences.
                </p>
                <button onClick={() => handleHubspotDealChange()}>
                  Confirm
                </button>
                <button onClick={() => setConfirmHubspotDeal(false)}>
                  Cancel
                </button>
              </div>
            </AddDealModal>
          </AddDealWrapper>
        )}
        {dealMappingError.error && (
          <AddDealWrapper onClick={(e) => handleClickOutside(e)}>
            <AddDealModal ref={modalRef}>
              <div className="confirm-oppty">
                <h2>This was already linked to another deal in Leadoff.</h2>
                <p>
                  Leadoff prevents linking to multiple deals to maintain data
                  integrity. This is currently linked to{" "}
                  {dealMappingError.deal_name}.
                </p>
                <button
                  onClick={() =>
                    setDealMappingError({ error: false, deal_name: "" })
                  }
                >
                  Close
                </button>
              </div>
            </AddDealModal>
          </AddDealWrapper>
        )}
      </DealPageWrapper>
    </PageWrapper>
  );
};

export default DealPage;
