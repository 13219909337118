import React, { useState, useEffect, useMemo } from "react";
import {
  Cell,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  LabelList,
  BarChart,
} from "recharts";
import {
  getOrganizationScores,
  getOrganizationUsers,
  getOrganizationInsights,
} from "api/helpers";
import { scoreToColor } from "colors";
import {
  ChartContainer,
  Title,
  FilterContainer,
  FilterSelect,
  StyledBarChart,
  SkeletonBar,
  NoContentMessage,
  ComparisonWrapper,
  DateInput,
  DateInputWrapper,
  DateLabel,
  DateFilterContainer,
  DateButton,
  StyledCalendarIcon,
} from "./ScoreChart.styled";
import { useSelector } from "react-redux";
import { Stage, UserData, Team } from "types";
import ComparisonList from "components/ComparisonList/ComparisonList";
import Calendar from "static/svgs/Calendar";

interface ScoreData {
  averageScore: number;
  category_name: string;
}

const score_data = [
  { averageScore: 73, category_name: "Contacts and Familiarity" },
  { averageScore: 41, category_name: "Metrics" },
  { averageScore: 28, category_name: "Economic Buyer" },
  { averageScore: 45, category_name: "Decision Criteria" },
  { averageScore: 49, category_name: "Decision Process" },
  { averageScore: 13, category_name: "Paperwork Process" },
  { averageScore: 71, category_name: "Identify Pain" },
  { averageScore: 47, category_name: "Champion" },
  { averageScore: 23, category_name: "Competition" },
  { averageScore: 72, category_name: "Value Proposition" },
];

interface ChartData extends ScoreData {
  formattedScore: number;
}

const CustomTooltip: React.FC<any> = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          backgroundColor: "black",
          padding: "5px 10px",
          border: "1px solid #ccc",
          borderRadius: "4px",
          color: "white",
        }}
      >
        <p style={{ margin: 0 }}>{`Score: ${payload[0].value.toFixed(2)}`}</p>
      </div>
    );
  }
  return null;
};

const ScoreChart: React.FC = () => {
  const today = new Date().toISOString().split("T")[0];

  const [scores, setScores] = useState<ChartData[]>([]);
  const [loading, setLoading] = useState(true);
  const [team, setTeam] = useState<Team | null>(null);
  const [rep, setRep] = useState<UserData | null>(null);
  const [stage, setStage] = useState<Stage | null>(null);
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>(today);
  const [showDates, setShowDates] = useState(false);
  const [users, setUsers] = useState<UserData[]>([]);
  const stages = useSelector((state: any) => state.app.stages);
  const [winningInsights, setWinningInsights] = useState<any>(null);
  const [notCoveredInsights, setNotCoveredInsights] = useState<any>(null);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const userData: UserData[] = await getOrganizationUsers();
        setUsers(userData);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };
    fetchUsers();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const filters = {
          team_id: team?.team_id,
          user_id: rep?._id,
          stage: stage?.label,
          start_date: startDate,
          end_date: endDate,
        };

        const [scoreData, insightsData] = await Promise.all([
          getOrganizationScores(filters),
          getOrganizationInsights(filters),
        ]);

        const formattedData: ChartData[] = scoreData.map((score: any) => ({
          ...score,
          formattedScore: score.averageScore,
        }));

        setScores(formattedData);
        setWinningInsights(insightsData?.did_well || []);
        setNotCoveredInsights(insightsData?.did_poorly || []);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [team, rep, stage, startDate, endDate]);

  const handleTeamChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedTeam =
      teams.find((t) => t.team_id === e.target.value) || null;
    setTeam(selectedTeam);
    setRep(null); // Clear rep when team changes
  };

  const handleRepChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedRep = users.find((r) => r._id === e.target.value) || null;
    setRep(selectedRep);
    setTeam(null); // Clear team when rep changes
  };

  // Create teams array from user data
  const teams = useMemo(() => {
    const teamMap = new Map<string, Team>();
    users.forEach((user) => {
      if (user.team_name && user.team_id) {
        teamMap.set(user.team_name, {
          team_name: user.team_name,
          team_id: user.team_id,
        });
      }
    });
    return Array.from(teamMap.values());
  }, [users]);

  const formatCategoryName = (name: string): string => {
    return name.replace(/([A-Z])/g, " $1").trim();
  };

  const renderSkeletonLoader = () => (
    <ResponsiveContainer width="100%" height="90%">
      <StyledBarChart
        layout="vertical"
        data={Array(10).fill({ formattedScore: 100 })}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      >
        <XAxis type="number" domain={[0, 100]} hide />
        <YAxis type="category" width={150} hide />
        <Bar dataKey="formattedScore" barSize={20}>
          {Array(10)
            .fill(null)
            .map((_, index) => (
              <Cell key={`cell-${index}`} fill="transparent" />
            ))}
          <LabelList
            dataKey="formattedScore"
            position="right"
            content={(props: any) => {
              const { x, y, height } = props;
              return (
                <SkeletonBar
                  x={0} // Set x to 0 to start from the left
                  y={y}
                  width={300} // Set a fixed width for the skeleton
                  height={height * 0.6} // Reduce height slightly
                  rx={4} // Add rounded corners
                  ry={4} // Add rounded corners
                />
              );
            }}
          />
        </Bar>
      </StyledBarChart>
    </ResponsiveContainer>
  );

  const renderChartContent = () => {
    if (loading) {
      return renderSkeletonLoader();
    }

    if (scores.length === 0) {
      return (
        <NoContentMessage>No data found with these filters</NoContentMessage>
      );
    }

    return (
      <ResponsiveContainer width="100%" height="90%">
        <BarChart
          layout="vertical"
          data={scores}
          margin={{ top: 5, right: 50, left: 20, bottom: 5 }}
        >
          <XAxis type="number" domain={[0, 100]} hide />
          <YAxis
            dataKey="category_name"
            type="category"
            tick={{ fill: "#333333" }}
            width={150}
            tickFormatter={formatCategoryName}
          />
          <Tooltip content={<CustomTooltip />} />
          <Bar
            dataKey="formattedScore"
            barSize={20}
            // Remove stroke and strokeWidth
          >
            <LabelList
              dataKey="formattedScore"
              position="right"
              fill="#333333" // Darker gray for better readability
            />
            {scores.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={scoreToColor(entry.formattedScore / 10).background}
                style={{ filter: "brightness(0.95)" }} // Slightly dim the colors
              />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    );
  };

  return (
    <>
      <ChartContainer>
        <div className="header">
          <Title>Average Deal Scores</Title>
          <FilterContainer>
            <FilterSelect
              value={team?.team_id || ""}
              onChange={handleTeamChange}
            >
              <option value="">All Teams</option>
              {teams.map((t) => (
                <option key={t.team_id} value={t.team_id}>
                  {t.team_name}
                </option>
              ))}
            </FilterSelect>
            <FilterSelect value={rep?._id || ""} onChange={handleRepChange}>
              <option value="">All Users</option>
              {users.map((r) => (
                <option key={r._id} value={r._id}>
                  {r.first_name} {r.last_name}
                </option>
              ))}
            </FilterSelect>
            <FilterSelect
              value={stage?.label || ""}
              onChange={(e) =>
                setStage(
                  stages.find((s: Stage) => s.label === e.target.value) || null
                )
              }
            >
              <option value="">All Stages</option>
              {stages.map((s: Stage) => (
                <option key={s.label} value={s.label}>
                  {s.label}
                </option>
              ))}
            </FilterSelect>
            <DateFilterContainer>
              <div>
                {showDates ? (
                  <DateInputWrapper>
                    <DateLabel>Start:</DateLabel>
                    <DateInput
                      type="date"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </DateInputWrapper>
                ) : (
                  <DateButton onClick={() => setShowDates(true)}>
                    <StyledCalendarIcon>
                      <Calendar />
                    </StyledCalendarIcon>
                  </DateButton>
                )}
              </div>
              <div>
                {showDates && (
                  <DateInputWrapper>
                    <DateLabel>End:</DateLabel>
                    <DateInput
                      type="date"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </DateInputWrapper>
                )}
              </div>
            </DateFilterContainer>
          </FilterContainer>
        </div>
        {renderChartContent()}
      </ChartContainer>
      <ComparisonWrapper>
        <ComparisonList
          title="Winning Behaviors"
          items={winningInsights?.top_five || []}
          isConsistent={true}
          loading={loading}
        />
        <ComparisonList
          title="Not Typically Covered"
          items={notCoveredInsights?.top_five || []}
          isConsistent={false}
          loading={loading}
        />
      </ComparisonWrapper>
    </>
  );
};

export default ScoreChart;
