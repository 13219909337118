import { useEffect, useState, FC } from "react";
import { InsightsWrapper } from "./Insights.styled";
import PageWrapper from "components/reusable/PageWrapper/PageWrapper";
import ScoreChart from "components/ScoreChart/ScoreChart";
import MeddicSpiderChart from "components/MeddicSpiderChart/MeddicSpiderChart";
import StageTimingChart from "components/StageTimingChart/StageTimingChart";
interface InsightsProps {}

const Insights: FC<InsightsProps> = () => {
  return (
    <PageWrapper pageTitle="Insights">
      <InsightsWrapper>
        <MeddicSpiderChart />
        <StageTimingChart />
        <ScoreChart />
      </InsightsWrapper>
    </PageWrapper>
  );
};

export default Insights;
