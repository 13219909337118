import React, { useEffect, useState } from "react";
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  ResponsiveContainer,
  PolarRadiusAxis,
  Tooltip,
} from "recharts";
import {
  CHART_COLORS,
  CHART_DIMENSIONS,
  FONT_SIZES,
  FONT_WEIGHTS,
  OPACITY,
  SkeletonChartContainer,
  SkeletonCircle,
  SkeletonCross,
} from "./SpiderChart.styled";
import {
  ChartContainer,
  TooltipContainer,
  TooltipTitle,
  TooltipEntryContainer,
  TooltipScore,
  TooltipInsight,
} from "./SpiderChart.styled";

interface FeedbackItem {
  feedback: Array<any>;
  feedback_summary: string;
}

interface CategoryData {
  averageScore: number;
  category_name: string;
  feedback_summary?: string;
}

interface TimingData {
  average_duration: number;
  stage: string;
}

interface CombinedDataItem {
  category: string;
  primary: number;
  comparison: number;
  primaryInsight?: string;    // Make optional
  comparisonInsight?: string; // Make optional
}

interface SpiderChartProps {
  data: CategoryData[] | TimingData[] | undefined;
  comparisonData?: CategoryData[] | TimingData[];
  loading: boolean;
  colorOne?: string;
  colorTwo?: string;
  primaryLabel?: string;
  comparisonLabel?: string;
}

interface CustomTooltipProps {
  active?: boolean;
  payload?: any[];
  label?: string;
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({
  active,
  payload,
  label,
}) => {
  if (active && payload && payload.length) {
    return (
      <TooltipContainer>
        <TooltipTitle>{label}</TooltipTitle>
        {payload.map((entry, index) => (
          <TooltipEntryContainer
            key={index}
            isLast={index === payload.length - 1}
          >
            <TooltipScore color={entry.color}>
              {entry.name}: {entry.value.toFixed(2)}
            </TooltipScore>
            {entry.payload[`${entry.dataKey}Insight`] && (
              <TooltipInsight>
                {entry.payload[`${entry.dataKey}Insight`]}
              </TooltipInsight>
            )}
          </TooltipEntryContainer>
        ))}
      </TooltipContainer>
    );
  }
  return null;
};

const SpiderChartSkeleton: React.FC = () => {
  return (
    <SkeletonChartContainer>
      <div className="quadrant top-right"></div>
      <div className="quadrant top-left"></div>
      <div className="quadrant bottom-left"></div>
      <div className="quadrant bottom-right"></div>
      <SkeletonCross />
    </SkeletonChartContainer>
  );
};

const SpiderChart: React.FC<SpiderChartProps> = ({
  data,
  comparisonData,
  loading,
  colorOne,
  colorTwo,
  primaryLabel,
  comparisonLabel,
}) => {
  const [activeCategory, setActiveCategory] = useState<string>("");

  const combinedData: CombinedDataItem[] = data?.map((item): CombinedDataItem => {
    console.log('Processing item:', item); // Add debug log
    
    if ('category_name' in item) {
      const matchingComp = (comparisonData as CategoryData[] | undefined)?.find(
        (comp): comp is CategoryData => 'category_name' in comp && comp.category_name === item.category_name
      );
      
      return {
        category: item.category_name,
        primary: item.averageScore,
        comparison: matchingComp?.averageScore ?? 0,
        primaryInsight: item.feedback_summary,
      };
    } else if ('stage' in item && 'average_duration' in item) {  // Add check for both properties
      console.log('TimingData branch hit:', item); // Add debug log
      
      const matchingComp = (comparisonData as TimingData[] | undefined)?.find(
        (comp): comp is TimingData => 
          'stage' in comp && 
          'average_duration' in comp && 
          comp.stage === item.stage
      );

      return {
        category: item.stage,
        primary: Number(item.average_duration) || 0,
        comparison: Number(matchingComp?.average_duration) || 0,
      };
    }
    
    console.log('Fallback case hit:', item); // Add debug log
    return {
      category: '',
      primary: 0,
      comparison: 0
    };
  }) || [];

  const getTooltipPosition = (label: string) => {
    if (!label) return {};

    const index = combinedData.findIndex((item) => item.category === label);
    const totalPoints = combinedData.length;
    const angle = (index / totalPoints) * 2 * Math.PI - Math.PI / 2;

    const x = Math.cos(angle);

    if (x < 0) {
      return {
        left: "90%",
        right: "-20%",
        top: "50%",
        transform: "translateY(-50%)",
        margin: "0 20px",
      };
    } else {
      return {
        left: "90%",
        right: "-40%",
        top: "50%",
        transform: "translateY(-50%)",
        margin: "0 20px",
      };
    }
  };

  const maxValue = Math.max(
    ...combinedData.map((item) => Math.max(item.primary, item.comparison))
  );

  const domainMax = Math.ceil(maxValue);
  const domainMin = 0;

  const range = domainMax - domainMin;
  const tickCount = Math.min(6, range + 1);

  if (loading) {
    return <SpiderChartSkeleton />;
  }

  return (
    <ChartContainer className="charts-wrapper">
      <ResponsiveContainer width="100%" height="100%">
        <RadarChart
          cx="50%"
          cy="50%"
          outerRadius="85%"
          innerRadius={CHART_DIMENSIONS.INNER_RADIUS}
          data={combinedData}
          onMouseMove={(e) => {
            if (e?.activeLabel) {
              setActiveCategory(e.activeLabel);
            }
          }}
          onMouseLeave={() => {
            setActiveCategory("");
          }}
        >
          <PolarGrid stroke={CHART_COLORS.GRID} />
          <PolarAngleAxis
            dataKey="category"
            tick={{
              fill: CHART_COLORS.TEXT,
              fontSize: FONT_SIZES.AXIS_LABELS,
              fontWeight: FONT_WEIGHTS.MEDIUM,
              dy: 5,
            }}
          />
          <PolarRadiusAxis
            angle={30}
            domain={[domainMin, domainMax]}
            tickCount={tickCount}
            tick={{
              fill: CHART_COLORS.TEXT,
              fontSize: FONT_SIZES.AXIS_TICKS,
              display: "none",
            }}
            axisLine={false}
            tickFormatter={(value) => `${value}`}
          />
          <Tooltip
            content={<CustomTooltip />}
            cursor={false}
            wrapperStyle={{
              zIndex: 100,
              cursor: "pointer",
              pointerEvents: "none",
              width: "400px",
              position: "absolute",
              transition: "all 0.2s ease-in-out",
              ...getTooltipPosition(activeCategory),
            }}
          />
          <Radar
            name={primaryLabel || "Filter #1 Data"}
            dataKey="primary"
            stroke={colorOne || CHART_COLORS.PRIMARY}
            fill={colorOne || CHART_COLORS.PRIMARY}
            fillOpacity={OPACITY.RADAR_FILL}
          />
          <Radar
            name={comparisonLabel || "Filter #2 Data"}
            dataKey="comparison"
            stroke={colorTwo || CHART_COLORS.COMPARISON}
            fill={colorTwo || CHART_COLORS.COMPARISON}
            fillOpacity={OPACITY.RADAR_FILL}
          />
        </RadarChart>
      </ResponsiveContainer>
    </ChartContainer>
  );
};

export default SpiderChart;
